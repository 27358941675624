// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--3VpLX";
export var aquamarine = "footer-module--aquamarine--2OQFf";
export var purple = "footer-module--purple--2bgKg";
export var footer__content = "footer-module--footer__content--p9htx";
export var footer__topRow = "footer-module--footer__topRow--sGmhG";
export var footer__logo = "footer-module--footer__logo--3b86k";
export var footer__media = "footer-module--footer__media--14_xt";
export var footer__menu = "footer-module--footer__menu--3vzho";
export var footer__copy = "footer-module--footer__copy--r-SOD";
export var footer__logos = "footer-module--footer__logos--20QIL";
export var footer__background = "footer-module--footer__background--1Gr3R";