import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import cx from "classnames";
import Header from "./Header/header";
import "./layout.css";
import "./animate.css";
import "@brainhubeu/react-carousel/lib/style.css";
import * as styles from "./layout.module.scss";
import Footer from "./Footer/footer";
import CookiesPopup from "./CookiesPopup/cookiesPopup";

interface Props {
  className?: string;
  menuColor?: string;
  footerColor?: string;
  formVisible?: boolean
}

const Layout: FunctionComponent<Props> = ({
  children,
  className,
  menuColor,
  footerColor,
  formVisible = true
}) => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top < 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  return (
    <div className={cx(styles.layoutWrapper, className)}>
      <div ref={ref}>
        <Header menuColor={menuColor} isSticky={isSticky} />
      </div>
      {children}
      <Footer formVisible={formVisible} footerColor={footerColor} />
      <CookiesPopup />
    </div>
  );
};

export default Layout;
