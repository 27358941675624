import { useI18next, I18nextContext, Link } from "gatsby-plugin-react-i18next";
import React, { useState, useContext } from "react";
import * as styles from "./languageSwitcher.module.scss";
import ClickOutside from "../../../ClickOutside/ClickOutside";
import cx from "classnames";

const LanguageSwitcher = ({ isSticky, menuColor }) => {
  const { languages, originalPath } = useI18next();
  const context = useContext(I18nextContext);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  return (
    <div
      className={cx({
        [styles.languagesWrapper]: true,
        [styles.languagesWrapper_sticky]: isSticky,
        [styles[menuColor]]: true,
      })}
    >
      <span
        role="button"
        onClick={() => setIsLangMenuOpen(true)}
        onKeyPress={() => setIsLangMenuOpen(true)}
        tabIndex={0}
      >
        <p className={styles.activeLanguage}>{context.language}</p>
      </span>
      {isLangMenuOpen && (
        <ClickOutside
          active={isLangMenuOpen}
          action={() => {
            setIsLangMenuOpen(false);
          }}
        >
          <ul className={styles.languages}>
            {languages.map((lng) => (
              <li key={lng} className={styles.languagesItem}>
                <Link
                  to={originalPath}
                  language={lng}
                  className={styles.languagesLink}
                >
                  {lng}
                </Link>
              </li>
            ))}
          </ul>
        </ClickOutside>
      )}
    </div>
  );
};

export default LanguageSwitcher;
