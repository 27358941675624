import { useI18next, I18nextContext, Link } from "gatsby-plugin-react-i18next";
import React, { useContext } from "react";
import * as styles from "./mobileLanguageSwitcher.module.scss";

const MobileLanguageSwitcher = () => {
  const { languages, originalPath } = useI18next();
  const context = useContext(I18nextContext);
  return (
    <div className={styles.languagesWrapper}>
      <ul className={styles.languages}>
        {languages.map((lng) => (
          <li key={lng} className={styles.languagesItem}>
            <Link
              to={originalPath}
              language={lng}
              className={`${styles.languagesLink} ${
                context.language === lng ? styles.activeLang : ""
              }`}
            >
              {lng}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MobileLanguageSwitcher;
