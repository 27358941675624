import * as styles from "./mobileMenu.module.scss";
import React, { useState } from "react";
import MobileLanguageSwitcher from "../../LanguageSwitcher/mobileLanguageSwitcher/mobileLanguageSwitcher";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import LogoSvg from "../../../../assets/svg/logo.svg";
import cx from "classnames";
import GetInTouchButton from "../../../GetInTouchButton/GetInTouchButton";

const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div className={`${styles.mobileMenuWrapper}  ${open ? styles.open : ""}`}>
      <Link to="/" aria-label="Home">
        <div
          className={cx({
            [styles.logo]: true,
            [styles.logo_open]: open,
          })}
        >
          <LogoSvg width={93} alt="Salve logo" />
        </div>
      </Link>

      <div
        className={`${styles.burger}  ${open ? styles.open : ""}`}
        onClick={() => setOpen(!open)}
        onKeyPress={() => setOpen(!open)}
        role="button"
        tabIndex={0}
        aria-label="Menu"
      >
        <div />
        <div />
        <div />
      </div>
      <GetInTouchButton />
      <ul className={`${styles.menu}  ${open ? styles.open : ""}`}>
        <li className={styles.menu_item}>
          <Link
            to="/for-clinics"
            className={styles.menu_link}
            activeClassName="activeLink"
          >
            {t("menu.forClinics")}
          </Link>
        </li>
        <li className={styles.menu_item}>
          <Link
            to="/for-patients"
            className={styles.menu_link}
            activeClassName="activeLink"
          >
            {t("menu.forPatients")}
          </Link>
        </li>
        <li className={styles.menu_item}>
          <Link
            to="/security"
            className={styles.menu_link}
            activeClassName="activeLink"
          >
            {t("menu.security")}
          </Link>
        </li>
        <li className={styles.menu_item}>
          <Link
            to="/about"
            className={styles.menu_link}
            activeClassName="activeLink"
          >
            {t("menu.aboutUs")}
          </Link>
          <ul className={styles.dropdown} aria-label="submenu">
          <li>
            <Link
              to="/articles"
              className={styles.menu_link}
              activeClassName="activeLink"
            >
              {t("menu.articles")}
            </Link>
          </li>
        </ul>
        </li>
        <li className={styles.menu_item}>
          <Link
            to="/integrations"
            className={styles.menu_link}
            activeClassName="activeLink"
          >
            {t("menu.integrations")}
          </Link>
        </li>
        <li className={styles.menu_item}>
          <Link
            to="/not-fertility-clinic"
            className={styles.menu_link}
            activeClassName="activeLink"
          >
            {t("menu.notAFertilityClinic")}
          </Link>
        </li>
      </ul>
      {open && <MobileLanguageSwitcher />}
    </div>
  );
};

export default MobileMenu;
