import * as styles from "./footer.module.scss";
import React from "react";
import cx from "classnames";
import FooterForm from "./FooterForm/footerForm";
import Media from "./Media/media";
import FooterMenu from "./FooterMenu/footerMenu";
import FooterLogos from "./FooterLogos/footerLogos";

import LogoSvg from "../../assets/svg/logo.svg";

const Footer = ({ footerColor, formVisible }) => (
  <footer className={cx([styles.footer, styles[footerColor]])}>
    <div className={cx([styles.footer__background, styles[footerColor]])} />
    {formVisible && <FooterForm />}
    <div className={styles.footer__content}>
      <div className={styles.footer__topRow}>
        <div className={styles.footer__logo}>
          <LogoSvg width={93} fill="white" />
        </div>
        <div className={styles.footer__media}>
          <Media />
        </div>
      </div>
      <div className={styles.footer__menu}>
        <FooterMenu />
      </div>
      <div className={styles.footer__copy}>©2020 by Salve</div>
    </div>
    <div className={cx(styles.footer__content, styles.footer__logos)}>
      <FooterLogos />
    </div>
  </footer>
);

export default Footer;
