import * as styles from "./webMenu.module.scss";
import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import GetInTouchButton from "../../../GetInTouchButton/GetInTouchButton";

const WebMenu = ({ menuColor, isSticky }) => {
  const { t } = useTranslation();
  return (
    <ul
      className={`${styles.menu} ${styles[menuColor]} ${
        isSticky ? styles.stick : ""
      }`}
    >
      <li className={styles.menuItem}>
        <Link
          to="/for-clinics"
          className={styles.menuLink}
          activeClassName="activeLink"
        >
          {t("menu.forClinics")}
        </Link>
      </li>
      <li className={styles.menuItem}>
        <Link
          to="/for-patients"
          className={styles.menuLink}
          activeClassName="activeLink"
        >
          {t("menu.forPatients")}
        </Link>
      </li>
      <li className={styles.menuItem}>
        <Link
          to="/security"
          className={styles.menuLink}
          activeClassName="activeLink"
        >
          {t("menu.security")}
        </Link>
      </li>
      <li className={styles.menuItem}>
        <Link
          to="/about"
          className={styles.menuLink}
          activeClassName="activeLink"
          aria-haspopup="true"
        >
          {t("menu.aboutUs")}
        </Link>
        <ul className={styles.dropdown} aria-label="submenu">
          <li>
            <Link
              to="/articles"
              className={styles.menuLink}
              activeClassName="activeLink"
            >
              {t("menu.articles")}
            </Link>
          </li>
        </ul>
      </li>
      <li className={styles.menuItem}>
        <Link
          to="/integrations"
          className={styles.menuLink}
          activeClassName="activeLink"
        >
          {t("menu.integrations")}
        </Link>
      </li>
      <li className={styles.menuItem}>
        <Link
          to="/not-fertility-clinic"
          className={styles.menuLink}
          activeClassName="activeLink"
        >
          {t("menu.notAFertilityClinic")}
        </Link>
      </li>
      <li className={styles.menuButton}>
        <GetInTouchButton blue={isSticky} />
      </li>
    </ul>
  );
};

export default WebMenu;
