import React from "react";
import WebMenu from "./webMenu/webMenu";
import * as styles from "./mainMenu.module.scss";
import LanguageSwitcher from "../LanguageSwitcher/webLanguageSwitcher/languageSwitcher";

const MainMenu = ({ menuColor, isSticky }) => {
  return (
    <div className={styles.center}>
      <WebMenu menuColor={menuColor} isSticky={isSticky} />
      <LanguageSwitcher menuColor={menuColor} isSticky={isSticky} />
    </div>
  );
};

export default MainMenu;
