import * as styles from "./GetInTouchButton.module.scss";
import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const GetInTouchButton = ({ blue = false }) => {
  const handleGetInTouchClick = () => {
    const formElement = document.querySelector("#contactForm");
    const headerElement = document.querySelector("header");
    const scrollDistance =
      window.pageYOffset + formElement.getBoundingClientRect().top - headerElement.offsetHeight;

    window.scroll({ top: scrollDistance, left: 0, behavior: "smooth" });
  };
  const { t } = useTranslation();
  return (
    <button
      type="button"
      onClick={handleGetInTouchClick}
      className={cx({
        [styles.button]: true,
        [styles.button_blue]: blue,
      })}
    >
      {t("button.getInTouch")}
    </button>
  );
};

export default GetInTouchButton;
