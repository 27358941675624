import * as styles from "./media.module.scss";
import React from "react";

import LinLogo from "../../../assets/svg/lin.svg";
import TwiterLogo from "../../../assets/svg/twiter.svg";
import InstagramLogo from "../../../assets/svg/instagram.svg";

const Media = () => (
  <div className={styles.media}>
    <a
      href="https://www.linkedin.com/company/salvehealth/"
      target="_blank"
      rel="noreferrer"
      aria-label="Linkedin"
    >
      <div className={styles.media__icon}>
        <LinLogo />
      </div>
    </a>
    <a
      href="https://twitter.com/salve_health?s=20"
      target="_blank"
      rel="noreferrer"
      aria-label="Twitter"
    >
      <div className={styles.media__icon}>
        <TwiterLogo />
      </div>
    </a>
    <a
      href="https://www.instagram.com/salve_health/"
      target="_blank"
      rel="noreferrer"
      aria-label="Instagram"
    >
      <div className={styles.media__icon}>
        <InstagramLogo />
      </div>
    </a>
  </div>
);

export default Media;
