import * as styles from "./header.module.scss";
import React from "react";
import MainMenu from "./MainMenu/mainMenu";
import Logo from "./Logo/logo";
import MobileMenu from "./MainMenu/mobileMenu/mobileMenu";
import cx from "classnames";

const Header = ({ menuColor, isSticky }) => {
  return (
    <>
      <div className={styles.headerSpace}></div>
      <header
        className={cx({
          [styles.header]: true,
          [styles.header_sticky]: isSticky,
        })}
      >
        <Logo isSticky={isSticky} />
        <MainMenu menuColor={menuColor} isSticky={isSticky} />
      </header>
      <MobileMenu />
    </>
  );
};

export default Header;
