// extracted by mini-css-extract-plugin
export var mobileMenuWrapper = "mobileMenu-module--mobileMenuWrapper--24WR7";
export var open = "mobileMenu-module--open--2uDcG";
export var logo = "mobileMenu-module--logo--1_HMM";
export var logo_open = "mobileMenu-module--logo_open--39hQD";
export var burger = "mobileMenu-module--burger--3cQln";
export var menu = "mobileMenu-module--menu--2e7Af";
export var menu_item = "mobileMenu-module--menu_item--3X5sn";
export var dropdown = "mobileMenu-module--dropdown--39Jhc";
export var menu_link = "mobileMenu-module--menu_link--3Qh92";
export var activeLink = "mobileMenu-module--activeLink--1HEFK";
export var languageSelect = "mobileMenu-module--languageSelect--333Ru";