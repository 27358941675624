// extracted by mini-css-extract-plugin
export var form = "footerForm-module--form--3IpuF";
export var form__row = "footerForm-module--form__row--2BPl1";
export var form__header = "footerForm-module--form__header--5ftPx";
export var form__inputWrapper = "footerForm-module--form__input-wrapper--1YA7f";
export var form__selectWrapper = "footerForm-module--form__select-wrapper--U7kMa";
export var form__input = "footerForm-module--form__input--Cf436";
export var form__textarea = "footerForm-module--form__textarea--AIp3c";
export var form__label = "footerForm-module--form__label--1TLDH";
export var form__prefix = "footerForm-module--form__prefix--1z1cY";
export var form__error = "footerForm-module--form__error--3xOBs";
export var checkbox = "footerForm-module--checkbox--3jbuq";
export var label = "footerForm-module--label--34LHM";
export var controls = "footerForm-module--controls--3F_iF";
export var policyLink = "footerForm-module--policyLink--1dLwe";
export var error = "footerForm-module--error--3Uig7";
export var footerHeader = "footerForm-module--footerHeader--2UPbT";
export var thankYou = "footerForm-module--thankYou--3b6U0";
export var thankYou_active = "footerForm-module--thankYou_active--1prH2";
export var thankYou__title = "footerForm-module--thankYou__title--3O3b6";
export var thankYou__copy = "footerForm-module--thankYou__copy--nZPkC";
export var thankYou__close = "footerForm-module--thankYou__close--Op2uK";