interface Values {
  messageType: string;
  firstName: string;
  lastName: string;
  email: string;
  phonePrefix: string;
  phoneNumber?: string;
  message: string;
  terms: boolean;
}

export function getSubmissionUrl(values: Values) {
  return `mailto:hello@salveapp.co.uk?subject=${encodeURIComponent(
    `Salve Website: Get in touch (${encodeURIComponent(values.messageType)})`
  )}&body=${encodeURIComponent(`${values.message}

------------------------------------------------------------------------

First name: ${values.firstName}
Last name: ${values.lastName}
Email: ${values.email}
Phone number: +${values.phonePrefix} ${values.phoneNumber}
Accepted terms: ${values.terms ? "✓" : "✗"}
`)}`
}
