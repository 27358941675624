import * as styles from "./logo.module.scss";
import LogoSvg from "../../../assets/svg/logo.svg";
import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import cx from "classnames";

const Logo = ({ isSticky }) => {
  return (
    <Link to="/" aria-label="Home">
      <div
        className={cx({
          [styles.logo]: true,
          [styles.logo_sticky]: isSticky,
        })}
      >
        <h1>
          <LogoSvg alt="Salve" />
        </h1>
      </div>
    </Link>
  );
};

export default Logo;
