/* eslint-disable jsx-a11y/no-onchange */

import * as styles from "./footerForm.module.scss";
import ButtonFull from "../../ButtonFull/buttonFull";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import cx from "classnames";
import CloseSvg from "../../../assets/svg/close.svg";
import { getSubmissionUrl } from "../FooterForm/submission";
import { useI18next } from "gatsby-plugin-react-i18next";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const requiredValidator = (v) => {
  if (v === "" || v === false || v === null) {
    return "footer.form.error.required";
  }
  return null;
};

const emailValidator = (v) => {
  if (!emailRegex.test(v)) {
    return "footer.form.error.email";
  }
  return null;
};

const termsValidator = (v) => {
  if (!v) {
    return "footer.form.error.terms";
  }
  return null;
};

export const validate = (value, validators = []) => {
  const error = validators.find((validator) => validator(value));
  return error ? error(value) : null;
};

const isValid = (errors) => !Object.values(errors).some((error) => error);

const validators = {
  messageType: [requiredValidator],
  company: [requiredValidator],
  firstName: [requiredValidator],
  lastName: [requiredValidator],
  email: [requiredValidator, emailValidator],
  phoneNumber: [],
  phonePrefix: [],
  message: [requiredValidator],
  terms: [termsValidator],
};
const initialValuesState = {
  messageType: "",
  company: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  phonePrefix: "",
  message: "",
  terms: "",
};

const initialErrorsState = {
  messageType: null,
  company: null,
  firstName: null,
  lastName: null,
  email: null,
  phoneNumber: null,
  phonePrefix: null,
  message: null,
  terms: null,
};
const FooterForm = () => {
  const [touched, setTouched] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [values, setValues] = useState({
    ...initialValuesState,
  });
  const [errors, setErrors] = useState({
    ...initialErrorsState,
  });

  const { t } = useTranslation();
  const {language} = useI18next();
  const privacyPolicyUrl = `https://salvehealth.com/policies/${language}/data-policy.pdf`;

  const setValue = (name, value, { validators }) => {
    setValues((values) => ({ ...values, [name]: value }));
    setErrors((errors) => ({ ...errors, [name]: validate(value, validators) }));
  };

  const sendForm = () => {
    window.location = getSubmissionUrl(values);
  };

  const handleSubmit = () => {
    const newErrors = Object.keys(values).reduce(
      (result, field) => ({
        ...result,
        [field]: validate(values[field], validators[field]),
      }),
      {}
    );
    setTouched(true);
    setErrors((errors) => ({ ...errors, ...newErrors }));

    if (isValid(newErrors)) {
      sendForm();
      // setIsSent(true) // Add back when form submission replaces mailto link
      setTouched(false);
      setValues({ ...initialValuesState });
      setErrors({ ...initialErrorsState });
    }
  };

  const phoneNumberError = () => {
    if (errors.phoneNumber) {
      return t(errors["phoneNumber"], {
        name: t("footer.form.phoneNumber.label"),
      });
    }

    if (errors.phonePrefix) {
      return t(errors["phonePrefix"], {
        name: t("footer.form.phonePrefix.label"),
      });
    }

    return null;
  };
  const closeThankYou = () => setIsSent(false);
  const valid = isValid(errors);

  const placeholders = {
    messageType: t("footer.form.messageType.label"),
    company: t("footer.form.company.label"),
    firstName: t("footer.form.firstName.label"),
    lastName: t("footer.form.lastName.label"),
    email: t("footer.form.email.label"),
    phoneNumber: t("footer.form.phoneNumber.label"),
    message: t("footer.form.message.label"),
  };

  return (
    <div className={styles.form} id="contactForm">
      <div
        className={cx({
          [styles.thankYou]: true,
          [styles.thankYou_active]: isSent,
        })}
      >
        <button
          type="button"
          className={styles.thankYou__close}
          onClick={closeThankYou}
          aria-label="Close"
        >
          <CloseSvg width={18} height={18} />
        </button>
        <div className={styles.thankYou__title}>
          {t("footer.form.thankYou.title")}
        </div>
        <div className={styles.thankYou__copy}>
          {t("footer.form.thankYou.copy")}
        </div>
      </div>
      <h3 className={styles.form__header}>{t("button.getInTouch")}</h3>
      <div className={cx(styles.form__inputWrapper, styles.form__selectWrapper)}>
        <select
          className={styles.form__input}
          value={values.messageType}
          onChange={(e) =>
            setValue("messageType", e.target.value, {
              validators: validators.messageType,
            })
          }
        >
          <option value="" disabled>
            {t("footer.form.messageType.placeholder")}
          </option>
          {[
            "footer.form.messageType.patient",
            "footer.form.messageType.teamMember",
            "footer.form.messageType.partner",
            "footer.form.messageType.emr",
          ].map((key) => (
            <option key={key}>{t(key)}</option>
          ))}
        </select>
      </div>
      <div className={styles.form__error}>
        {touched &&
          errors["messageType"] &&
          t(errors["messageType"], {
            name: t("footer.form.messageType.label"),
          })}
      </div>
      <div className={styles.form__inputWrapper}>
        <input
          type="text"
          id="company"
          className={styles.form__input}
          value={values.company}
          onChange={(e) =>
            setValue("company", e.target.value, {
              validators: validators.company,
            })
          }
        />
        {!values.company && (
          <label htmlFor="company" className={styles.form__label}>{placeholders.company}</label>
        )}
      </div>
      <div className={styles.form__error}>
        {touched &&
          errors["company"] &&
          t(errors["company"], {
            name: t("footer.form.company.label"),
          })}
      </div>
      <div className={styles.form__inputWrapper}>
        <input
          type="text"
          id="firstName"
          className={styles.form__input}
          value={values.firstName}
          onChange={(e) =>
            setValue("firstName", e.target.value, {
              validators: validators.firstName,
            })
          }
        />
        {!values.firstName && (
          <label htmlFor="firstName" className={styles.form__label}>{placeholders.firstName}</label>
        )}
      </div>
      <div className={styles.form__error}>
        {touched &&
          errors["firstName"] &&
          t(errors["firstName"], {
            name: t("footer.form.firstName.label"),
          })}
      </div>
      <div className={styles.form__inputWrapper}>
        <input
          type="text"
          id="lastName"
          className={styles.form__input}
          value={values.lastName}
          onChange={(e) =>
            setValue("lastName", e.target.value, {
              validators: validators.lastName,
            })
          }
        />
        {!values.lastName && (
          <label htmlFor="lastName" className={styles.form__label}>{placeholders.lastName}</label>
        )}
      </div>
      <div className={styles.form__error}>
        {touched &&
          errors["lastName"] &&
          t(errors["lastName"], {
            name: t("footer.form.lastName.label"),
          })}
      </div>
      <div className={styles.form__inputWrapper}>
        <input
          type="text"
          id="email"
          className={styles.form__input}
          value={values.email}
          onChange={(e) =>
            setValue("email", e.target.value, {
              validators: validators.email,
            })
          }
        />
        {!values.email && (
          <label htmlFor="email" className={styles.form__label}>{placeholders.email}</label>
        )}
      </div>
      <div className={styles.form__error}>
        {touched &&
          errors["email"] &&
          t(errors["email"], {
            name: t("footer.form.email.label"),
          })}
      </div>
      <div className={styles.form__row}>
        <div className={styles.form__prefix}>
          <input
            type="text"
            id="phonePrefix"
            placeholder={"00"}
            className={styles.form__input}
            value={values.phonePrefix}
            onChange={(e) =>
              setValue("phonePrefix", e.target.value, {
                validators: validators.phonePrefix,
              })
            }
          />
        </div>

        <div className={styles.form__inputWrapper}>
          <input
            type="text"
            id="phoneNumber"
            className={styles.form__input}
            value={values.phoneNumber}
            onChange={(e) =>
              setValue("phoneNumber", e.target.value, {
                validators: validators.phoneNumber,
              })
            }
          />
          {!values.phoneNumber && (
            <label htmlFor="phoneNumber" className={styles.form__label}>{placeholders.phoneNumber}</label>
          )}
        </div>
      </div>
      <div className={styles.form__error}>{touched && phoneNumberError()}</div>
      <div className={styles.form__inputWrapper}>
        <textarea
          id="message"
          className={cx([styles.form__input, styles.form__textarea])}
          value={values.message}
          onChange={(e) =>
            setValue("message", e.target.value, {
              validators: validators.message,
            })
          }
        />
        {!values.message && (
          <label htmlFor="message" className={styles.form__label}>{placeholders.message}</label>
        )}
      </div>
      <div className={styles.form__error}>
        {touched &&
          errors["message"] &&
          t(errors["message"], {
            name: t("footer.form.message.label"),
          })}
      </div>
      <input
        type="checkbox"
        id="terms"
        className={styles.checkbox}
        checked={values.terms}
        onChange={(e) =>
          setValue("terms", e.target.checked, {
            validators: validators.terms,
          })
        }
      />
      <label htmlFor="terms" className={styles.label}>
        <Trans
          i18nKey="footer.form.terms"
          components={{
            privacy: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                target="__blank"
                href={privacyPolicyUrl}
                className={styles.policyLink}
              />
            ),
          }}
        ></Trans>
      </label>
      <div className={styles.form__error}>
        {touched && errors.terms && t(errors.terms)}
      </div>

      <div className={styles.controls}>
        <ButtonFull
          disabled={touched && !valid}
          onClick={handleSubmit}
          textId="button.send"
        />
      </div>
    </div>
  );
};

export default FooterForm;
