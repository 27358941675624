import React, { useRef, useEffect } from "react";

const ClickOutside = React.memo(({ children, active, action }) => {
  const node = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }
      action();
    };

    if (active) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [active, action]);

  return <div ref={node}>{children}</div>;
});

export default ClickOutside;
