import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Trans, useTranslation } from "react-i18next";
import * as styles from "./cookiesPopup.module.scss";
import CloseSvg from "../../assets/svg/close.svg";
import { loadAnalytics } from "../../lib/analytics";
import { useI18next } from "gatsby-plugin-react-i18next";

const cookieName = "gdpr_accepted";
const thirtyDaysInSeconds = 2592000;

function CookiesPopup() {
  const { t } = useTranslation();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [cookies, setCookie] = useCookies([cookieName]);
  const {language} = useI18next();
  const dataPolicyUrl = `/policies/${language}/data-policy.pdf`;
  const cookiePolicy = `/documents/cookie-policy.pdf`;

  const gdprCookie = cookies[cookieName];

  useEffect(() => {
    if (gdprCookie) {
      loadAnalytics();
    } else {
      setPopupVisible(true);
    }
  }, [gdprCookie]);

  function onAgree() {
    setCookie(cookieName, true, { maxAge: thirtyDaysInSeconds });
    setPopupVisible(false);
  }

  function onClose() {
    setPopupVisible(false);
  }

  return (
    <>
      {isPopupVisible && (
        <div className={styles.cookiesPopup}>
          <div className={styles.cookiesTop}>
            <h5 className={styles.cookiesTitle}>{t("cookieBanner.header")}</h5>
            <button
              className={styles.cookiesClose}
              type="button"
              onClick={onClose}
            >
              <CloseSvg />
            </button>
          </div>
          <p className={styles.cookiesDescription}>
            <Trans
              i18nKey="cookieBanner.description"
              components={{
                dataPolicy: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a href={dataPolicyUrl} target="_blank" rel="noreferrer" />
                ),
                cookiePolicy: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a href={cookiePolicy} target="_blank" rel="noreferrer" />
                ),
              }}
            />
          </p>
          <div className={styles.actionButtons}>
            <button className={styles.rejectCookiesBtn} onClick={onClose}>
              {t("cookieBanner.reject")}
            </button>
            <button className={styles.acceptCookiesBtn} onClick={onAgree}>
              {t("cookieBanner.accept")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default CookiesPopup;
