import * as styles from "./footerMenu.module.scss";
import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import {
  HELP_CENTER_CLINIC_URL,
  HELP_CENTER_PATIENT_URL,
} from "../../../constants/app.contants";
import { useI18next } from "gatsby-plugin-react-i18next";

const FooterMenu = () => {
  const { t } = useTranslation();

  const {language} = useI18next();
  const privacyPolicyUrl = `https://salvehealth.com/policies/${language}/data-policy.pdf`;
  const termsUrl = `https://salvehealth.com/policies/${language}/eula.pdf`;

  return (
    <ul className={styles.menu}>
      <li className={styles.menu__item}>
        <Link to="/for-clinics">{t("menu.forClinics")}</Link>
      </li>
      <li className={styles.menu__item}>
        <Link to="/for-patients">{t("menu.forPatients")}</Link>
      </li>
      <li className={styles.menu__item}>
        <Link to="/security"> {t("menu.security")}</Link>
      </li>
      <li className={styles.menu__item}>
        <Link to="/about"> {t("menu.aboutUs")}</Link>
      </li>
      <li className={styles.menu__item}>
        <Link to="/integrations">{t("menu.integrations")}</Link>
      </li>
      <li className={styles.menu__item}>
        <Link to="/not-fertility-clinic">{t("menu.notAFertilityClinic")}</Link>
      </li>
      <li className={styles.menu__item}>
        <a target="__blank" href={HELP_CENTER_CLINIC_URL}>
          {t("footer.helpCenterClinic")}
        </a>
      </li>
      <li className={styles.menu__item}>
        <a target="__blank" href={HELP_CENTER_PATIENT_URL}>
          {t("footer.helpCenterPatient")}
        </a>
      </li>
      <li className={styles.menu__item}>
        <a target="__blank" href={termsUrl}>
          {t("footer.terms")}
        </a>
      </li>
      <li className={styles.menu__item}>
        <a target="__blank" href={privacyPolicyUrl}>
          {t("footer.policy")}
        </a>
      </li>
    </ul>
  );
};

export default FooterMenu;
