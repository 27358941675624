import * as styles from "./buttonFull.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";

const ButtonFull = ({ textId, Icon, onClick, disabled }) => {
  const { t } = useTranslation();
  return (
    <button
      disabled={disabled}
      onClick={onClick && onClick}
      className={styles.button}
    >
      {Icon && (
        <span className={styles.icon}>
          <Icon />
        </span>
      )}
      <span>{t(textId)}</span>
    </button>
  );
};

export default ButtonFull;
