import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  APP_STORE_URL_ANDROID,
  APP_STORE_URL_IOS,
  PATIENT_WEB_PORTAL_URL,
} from "../../../constants/app.contants";
import * as styles from "./footerLogos.module.scss";

function FooterLogos() {
  return (
    <>
      <div className={styles.storeLogos}>
        <a href={APP_STORE_URL_IOS} target="_blank" rel="noreferrer">
          <StaticImage
            src="./images/app-store.png"
            alt="Download on the App Store"
            height={44}
            loading="lazy"
            placeholder="tracedSVG"
            className={styles.left}
          />
        </a>
        <a href={APP_STORE_URL_ANDROID} target="_blank" rel="noreferrer">
          <StaticImage
            src="./images/play-store.png"
            alt="Get it on Google Play"
            height={44}
            loading="lazy"
            placeholder="tracedSVG"
            className={styles.left}
          />
        </a>
        <a href={PATIENT_WEB_PORTAL_URL} target="_blank" rel="noreferrer">
          <StaticImage
            src="./images/patient-web-portal.png"
            alt="Visit on the patient web portal"
            height={44}
            loading="lazy"
            placeholder="tracedSVG"
          />
        </a>
      </div>
      <div className={styles.spacer} />
      <StaticImage
        src="./images/HIPAA.png"
        alt="HIPAA compliance Logo"
        height={50}
        loading="lazy"
        placeholder="tracedSVG"
        className={styles.logo}
      />
      <StaticImage
        src="./images/G-Cloud.png"
        alt="EU G-cloud supplier Logo"
        height={50}
        loading="lazy"
        placeholder="tracedSVG"
        className={styles.logo}
      />
      <StaticImage
        src="./images/GDPR.png"
        alt="GDPR compliant Logo"
        height={50}
        loading="lazy"
        placeholder="tracedSVG"
        className={styles.logo}
      />
      <StaticImage
        src="./images/NHS.png"
        alt="NHS Logo"
        height={50}
        loading="lazy"
        placeholder="tracedSVG"
        className={styles.logo}
      />
    </>
  );
}

export default FooterLogos;
